import { groq } from 'next-sanity'
import { z } from 'zod'

export const footerData = groq`
*[_type == 'footer'] {
  socialMedia {
    youTube,
    facebook,
    weChat,
    instagram,
    _type,
  },
  links[] {
    _key,
    title,
    newTab,
    desktopEnabled,
    mobileEnabled,
    _type,
    _type=='menuItemLink' => {
      link,
    },
    _type=='menuItemPage' => {
      anchor,
      page->{
        'url': slug.current,
      },
    },
  },
}`

const menuItemNavigationType = z.enum(['menuItemLink', 'menuItemPage'])

const FooterSocialMediaSchema = z
  .object({
    facebook: z.string().url().nullable(),
    youTube: z.string().url().nullable(),
    weChat: z.string().url().nullable(),
    instagram: z.string().url().nullable(),
    _type: z.literal('social'),
  })
  .nullable()

export const FooterMenuItemBaseSchema = z.object({
  _key: z.string(),
  title: z.string().nullable(),
  newTab: z.boolean(),
  desktopEnabled: z.boolean(),
  mobileEnabled: z.boolean(),
})

const FooterLinkSchema = z.discriminatedUnion('_type', [
  FooterMenuItemBaseSchema.extend({
    _type: z.literal(menuItemNavigationType.enum.menuItemLink),
    link: z.string().nullable(),
  }),
  FooterMenuItemBaseSchema.extend({
    _type: z.literal(menuItemNavigationType.enum.menuItemPage),
    anchor: z.string().nullable(),
    page: z.object({ url: z.string() }).nullable(),
  }),
])

export const FooterDataSchema = z.array(
  z.object({
    socialMedia: FooterSocialMediaSchema,
    links: z.array(FooterLinkSchema).nullable(),
  })
)

export type FooterDataSchemaType = z.infer<typeof FooterDataSchema>
