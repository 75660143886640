import { DzSelectorBanner as DzSelectorBannerBasic } from '@zwirner/design-system'
import Link from 'next/link'
import type { ComponentProps } from 'react'

type DzSelectorBannerProps = ComponentProps<typeof DzSelectorBannerBasic>
type Props = Omit<DzSelectorBannerProps, 'LinkElement'>

export const DzSelectorBanner = (props: Props) => {
  const dzSelectorBannerProps = { ...props, LinkElement: Link } as DzSelectorBannerProps
  return <DzSelectorBannerBasic {...dzSelectorBannerProps} />
}

DzSelectorBanner.displayName = 'DzSelectorBannerWrapper'
